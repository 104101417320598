import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'

export default function breadcrumbBroadcastPage() {
  const { t } = useI18n()
  const { getAttributeTranslation } = useLocale()

  function basePOI() {
    return [{
      text: t('ttmt.breadcrumbs.points_of_interest.points_of_interest'),
      link: true,
      to: '/points-of-interest',
    }]
  }

  function show(broadcastPage) {
    return [
      ...basePOI(),
      {
        text: getAttributeTranslation(broadcastPage.relationships?.point_of_interest?.attributes?.title),
        link: true,
        to: `/points-of-interest/${broadcastPage.relationships?.point_of_interest?.id}`,
      },
      {
        text: t('ttmt.breadcrumbs.broadcast.page_edition'),
        link: false,
        to: '/broadcast',
      },
    ]
  }

  function create(pointOfInterest) {
    return [
      ...basePOI(),
      {
        text: getAttributeTranslation(pointOfInterest?.attributes?.title),
        link: true,
        to: `/points-of-interest/${pointOfInterest.id}`,
      },
      {
        text: t('ttmt.breadcrumbs.broadcast.page_creation'),
        link: false,
        to: '/broadcast',
      },
    ]
  }

  return {
    show,
    create,
  }
}
