<template>
  <app-card-item
    class="p-4 max-w-screen-md"
  >
    <h1>{{ t('ttmt.broadcast.frontpage.link_of_broadcast_page') }}</h1>

    <app-copy-clipboard
      class="rounded bg-slate-200 px-3 py-2 w-max mt-4"
      :text="url"
      :size="16"
    />
  </app-card-item>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppCopyClipboard from '@shared/components/ui/AppCopyClipboard.vue'

const { t } = useI18n()

defineProps({
  url: {
    type: String,
    required: true,
  },
})
</script>
