<template>
  <div
    class="flex flex-col space-y-8"
  >
    <!-- Page orientation -->
    <div>
      <form-group
        name="orientation"
        type="radio"
        :form-control-props="{
          options: displayOrientationOptions,
        }"
      />
    </div>

    <!-- BroadcastPage is published -->
    <form-group
      name="published"
      type="switch"
    />

    <!-- BroadcastPage is muted -->
    <form-group
      name="muted"
      type="switch"
    />

    <!-- Logo -->
    <form-label
      :label="t('validation.attributes.logo')"
      class="mb-3 block"
    />

    <!-- Logo -->
    <logo-form-fields
      name-prefix="logo"
      :resource="resource.relationships?.logo"
      class="mb-5"
    />

    <!-- Gallery -->
    <form-label
      :label="t('validation.attributes.gallery')"
      class="mb-3 block"
    />

    <form-hint
      :hint="t('ttmt.broadcast.form.hints.images')"
    />

    <images-form-nested
      name="images"
      :resource="resource"
      class="mb-5"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useSelectOptions from '@shared/hooks/form/selectOptions'
import FormHint from '@shared/components/form/FormHint.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import ImagesFormNested from '@extranet/components/resources/image/ImagesFormNested.vue'
import LogoFormFields from '@extranet/components/resources/point_of_interest/logo/LogoFormFields.vue'

const { t } = useI18n()

const {
  displayOrientationOptions,
} = useSelectOptions()

defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})
</script>
